import { useState } from 'react'
import client from '../utils/apollo-client'
import { Flex } from '@chakra-ui/react'
import { ProductContext } from '../store'
import {
  FilterSidebar,
  Sidebar,
  Headerbar,
  BaseLayout,
  ProductGallery,
  HtmlHead,
} from '../c'
import { GET_INITAL_DATA } from '../gql'
import { CheckoutButton } from '../c/CheckoutButton'

export default function Home({ _products = [], brands = [] }) {
  const [products, setProducts] = useState([..._products])
  return (
    <>
      <HtmlHead />
      <ProductContext.Provider value={[products, setProducts]}>
        <BaseLayout>
          <Headerbar withSearch />
          <Flex minW='100%' grow='1' direction={['column', 'row']}>
            <Sidebar>
              <FilterSidebar brands={brands} />
            </Sidebar>
            <Flex
              as='main'
              pl={{ md: 10, sm: 0 }}
              grow='1'
              bg='white'
              align='start'
              pos='relative'
              pb={12}
            >
              <ProductGallery initProducts={[..._products]} />
              <CheckoutButton />
            </Flex>
          </Flex>
        </BaseLayout>
      </ProductContext.Provider>
    </>
  )
}

export async function getServerSideProps() {
  const { data } = await client.query({
    query: GET_INITAL_DATA,
  })

  return {
    props: {
      brands: data.printer_brands || [],
      _products: data.products || [],
    },
  }
}
